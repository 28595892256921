import { Link } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not Found" />
    <h1>Not Found</h1>
    <p>
      This route does not exist. Go back <Link to="/">home</Link>?
    </p>
  </Layout>
)

export default NotFoundPage
